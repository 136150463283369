import { FilterTransformer } from '@core/services/api/filter/filter-parser';
import {
  ApiFilterOperatorResponse,
  ApiFilterOperatorResponseType,
  ApiFilterPropertyResponse,
  ApiFilterPropertyResponseType,
} from '@core/services/api/interfaces/api-response';
import { Filter } from '@core/services/api/filter/interfaces/filter';
import { DateFilterOperator } from '@core/services/api/filter/enums/date-filter-operator';

export class DateFilterTransformer implements FilterTransformer {
  filterProperty: ApiFilterPropertyResponseType = 'dateFilter_properties';
  filterOperator: ApiFilterOperatorResponseType = 'dateFilter_operators';

  toFilter(
    propertyResponse: ApiFilterPropertyResponse,
    operatorResponse: ApiFilterOperatorResponse
  ): Filter[] {
    if (!propertyResponse || !operatorResponse) {
      return [];
    }
    return Object.keys(propertyResponse).map(key => {
      return {
        property: {
          name: key,
          config: propertyResponse[key],
        },
        operators: Object.keys(operatorResponse).map(operatorName => {
          return {
            // @ts-ignore
            operator: DateFilterOperator[operatorName.toUpperCase()],
            name: operatorName,
          };
        }),
      };
    });
  }
}
