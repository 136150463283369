import { HttpClient } from '@angular/common/http';
import { ApiOptions } from '@core/services/api/interfaces/api-options';
import { PaginatedResult } from '@core/services/api/interfaces/paginated-result';
import { environment } from '@environment/environment';
import { map, Observable } from 'rxjs';
import { ApiResponse } from '@core/services/api/interfaces/api-response';
import { FilterParser } from '@core/services/api/filter/filter-parser';
import { PropertyFilters } from '@core/services/api/models/property-filter';

export abstract class ApiService<T> {
  abstract readonly endpoint: string;
  readonly apiUrl = environment.apiCoreUrl;

  get url(): string {
    return `${this.apiUrl}/${this.endpoint}`;
  }

  get actionUrl(): string {
    return `${this.apiUrl}/actions/${this.endpoint}`;
  }

  constructor(public http: HttpClient) {}

  index(
    options?: ApiOptions,
    includeEntities: string[] | null = null,
    propertyFilters: PropertyFilters<T> | null = null
  ): Observable<PaginatedResult<T>> {
    const query = this.buildQuery(options, includeEntities, propertyFilters);
    return this.http
      .get<ApiResponse<T>>(`${this.url}?${query}`)
      .pipe(
        map((response: ApiResponse<T>) => this.createPaginatedResult(response))
      );
  }

  show(id: number, includeEntities: string[] | null = null): Observable<T> {
    return this.http.get<T>(this.getEntityUrl(id, includeEntities));
  }

  create(data: T, includeEntities: string[] | null = null): Observable<T> {
    console.log('create', data);
    return this.http.post<T>(this.getUrl(includeEntities), data);
  }

  update(
    id: number,
    data: T,
    includeEntities: string[] | null = null
  ): Observable<T> {
    return this.http.put<T>(this.getEntityUrl(id, includeEntities), data);
  }

  delete(id: number, includeEntities: string[] | null = null): Observable<any> {
    return this.http.delete(this.getEntityUrl(id, includeEntities));
  }

  clone(
    id: number,
    data: Partial<T>,
    includeEntities: string[] | null = null
  ): Observable<T> {
    return this.http.post<T>(
      `${this.getEntityUrl(id, includeEntities, 'copy')}`,
      data
    );
  }

  import(
    data: Partial<T>,
    includeEntities: string[] | null = null
  ): Observable<T> {
    return this.http.post<T>(this.getUrl(includeEntities, 'import'), data);
  }

  distinct(field: string, options: any): Observable<string[]> {
    const query = this.buildQuery(options, null, null);
    return this.http.get<string[]>(
      `${this.actionUrl}/distinct/${field}${query ? `?${query}` : ''}`
    );
  }

  private buildIncludeEntitiesQuery(includeEntities: string[] | null): string {
    if (includeEntities === null) {
      return '';
    }
    return includeEntities.length > 0
      ? `includeEntities=${encodeURIComponent(JSON.stringify(includeEntities))}`
      : 'includeEntities';
  }

  private buildFilterPropertiesQuery(
    propertyFilters: PropertyFilters<any> | null
  ): string {
    if (!propertyFilters?.length) {
      return '';
    }

    const prefix = 'properties';

    return propertyFilters
      .map(propertyFilter => propertyFilter.extract(prefix))
      .join('');
  }

  protected getEntityUrl(
    id: number,
    includeEntities: string[] | null,
    action: string | null = null
  ): string {
    const includeEntitiesQuery =
      this.buildIncludeEntitiesQuery(includeEntities);
    return `${action ? this.actionUrl : this.url}/${id}${action ? `/${action}` : ''}${includeEntitiesQuery ? `?${includeEntitiesQuery}` : ''}`;
  }

  protected getUrl(
    includeEntities: string[] | null,
    action: string | null = null
  ): string {
    const includeEntitiesQuery =
      this.buildIncludeEntitiesQuery(includeEntities);
    return `${action ? `${this.actionUrl}/${action}` : this.url}${includeEntitiesQuery ? `?${includeEntitiesQuery}` : ''}`;
  }

  private buildQuery(
    options: ApiOptions | null = null,
    includeEntities: string[] | null,
    propertyFilters: PropertyFilters<T> | null
  ): string {
    const queryParts: string[] = [];

    const includeEntitiesQuery =
      this.buildIncludeEntitiesQuery(includeEntities);

    if (includeEntitiesQuery) queryParts.push(includeEntitiesQuery);

    const propertyFiltersQuery =
      this.buildFilterPropertiesQuery(propertyFilters);

    if (propertyFiltersQuery)
      queryParts.push(propertyFiltersQuery.slice(0, -1));
    //                                     ¯¯¯¯¯¯¯¯¯¯¯¯¯ => removes trailing '&'

    if (options?.page) queryParts.push(`page=${options.page}`);
    if (options?.pageSize) queryParts.push(`pageSize=${options.pageSize}`);
    if (options?.search)
      queryParts.push(`search=${encodeURIComponent(options.search)}`);

    if (options?.order) {
      for (const field in options.order) {
        if (Object.hasOwnProperty.call(options.order, field)) {
          queryParts.push(`order[${field}]=${options.order[field]}`);
        }
      }
    }

    if (options?.filter) {
      for (const field in options.filter) {
        if (Object.hasOwnProperty.call(options.filter, field)) {
          const value = options.filter[field];
          if (Array.isArray(value)) {
            value.forEach(item => {
              queryParts.push(`${field}[]=${encodeURIComponent(item)}`);
            });
          } else {
            queryParts.push(`${field}=${encodeURIComponent(value)}`);
          }
        }
      }
    }

    return queryParts.join('&');
  }

  private createPaginatedResult = (
    response: ApiResponse<T>
  ): PaginatedResult<T> => ({
    items: response['hydra:member'],
    totalCount: response['hydra:totalItems'],
    properties: new FilterParser().parse(response),
  });
}
