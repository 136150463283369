import {
  ApiFilterOperatorResponse,
  ApiFilterOperatorResponseType,
  ApiFilterPropertyResponse,
  ApiFilterPropertyResponseType,
  ApiResponse,
  ApiSearchMappingResponse,
} from '@core/services/api/interfaces/api-response';
import { Filter } from '@core/services/api/filter/interfaces/filter';
import { ExtendedFilterTransformer } from '@core/services/api/filter/transformer/extend-filter-transformer/extend-filter-transformer';
import { DateFilterTransformer } from '@core/services/api/filter/transformer/date-filter-transformer/date-filter-transformer';
import { ReferenceFilterTransformer } from '@core/services/api/filter/transformer/reference-filter-transformer/reference-filter-transformer';
import { flatten } from '@core/helpers/utilities/flatten';

export interface FilterTransformer {
  filterProperty: ApiFilterPropertyResponseType;
  filterOperator: ApiFilterOperatorResponseType;
  toFilter: (
    propertyResponse: ApiFilterPropertyResponse,
    operatorResponse: ApiFilterOperatorResponse
  ) => Filter[];
}

export class FilterParser {
  // Adding further transformers here
  private transformers: FilterTransformer[] = [
    new ExtendedFilterTransformer(),
    new DateFilterTransformer(),
    new ReferenceFilterTransformer(),
  ];

  public parse<T>(response: ApiResponse<T>): Filter[] {
    return flatten(
      this.transformers.map(transformer =>
        this.extractFilters(response, transformer)
      )
    );
  }

  private extractFilters<T>(
    response: ApiResponse<T>,
    transformer: FilterTransformer
  ): Filter[] {
    const propertyResponse = response['hydra:search']['hydra:mapping'].find(
      (item: ApiSearchMappingResponse) =>
        item.variable === transformer.filterProperty
    )?.property as ApiFilterPropertyResponse;

    const operatorResponse = response['hydra:search']['hydra:mapping'].find(
      (item: ApiSearchMappingResponse) =>
        item.variable === transformer.filterOperator
    )?.property as ApiFilterOperatorResponse;

    return transformer.toFilter(propertyResponse, operatorResponse);
  }
}
